var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RmaOverviewContainer',{ref:"rmaOverviewContainer",attrs:{"type":_vm.type},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var rmas = ref.rmas;
var status = ref.status;
return [_c('div',[_c('ProgressOverlay',{attrs:{"progress":status === _vm.rmaStates.LOADING ||
          status === _vm.rmaStates.CLOSING ||
          status === _vm.rmaStates.UPDATING,"hide-content-on-progress":status === _vm.rmaStates.UPDATING ? false : true}},[(rmas.length)?_c('Table',{attrs:{"itemKey":"id","items":rmas,"clickable":""},on:{"click":function (item) { return _vm.$router.push({ name: '/selfservice/returns/:id', params: { id: item.id } }); }}},[_c('TableColumn',{attrs:{"header":"Returnerings id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Oprettet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_c('Created',{attrs:{"data":item,"index":0}})]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Antal varer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_c('LinesQuantity',{attrs:{"data":item,"index":0}})]}}],null,true)}),_c('TableColumn',{attrs:{"header":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
return [_c('Status',{attrs:{"data":item,"index":0}})]}}],null,true)})],1):_c('EmptyStateDialog',{attrs:{"title":"Ingen returneringer fundet","message":"Vi kunne ikke finde nogen returneringer for denne konto."}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }