export const Mutation = {
  SET_RMA: 'SET_RMA',
  SET_STATUS: 'SET_STATUS',
  UPSERT_COMMENT: 'UPSERT_COMMENT',
  UPSERT_LINE_COMMENT: 'UPSERT_LINE_COMMENT',
  UPDATE_RMA: 'UPDATE_RMA',
  UPSERT_DRAFT_LINE: 'UPSERT_DRAFT_LINE',
  REMOVE_DRAFT_LINE: 'REMOVE_DRAFT_LINE',
  CLEAR_DRAFT_LINES: 'CLEAR_DRAFT_LINES',
  REMOVE_LINE: 'REMOVE_LINE',
  SET_RETURN_METHOD_ID: 'SET_RETURN_METHOD_ID',
};

export const Actions = {};
