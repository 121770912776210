<template>
  <RmaStatusLabel :status="data.status" :solid="true" :events="data.events" />
</template>

<script>
import RmaStatusLabel from '@scenes/SelfserviceScene/components/RmaStatusLabel';

export default {
  name: 'Status',
  components: {
    RmaStatusLabel,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {},
};
</script>
