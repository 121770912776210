import { getRmas } from '@services/thansen/rma';

import { RmaStates } from '@types/Rma';

import { Mutation } from './types';

export default {
  namespaced: true,
  state: {
    type: '',
    rmas: [],
    status: RmaStates.LOADING,
  },

  getters: {},

  actions: {
    getRmas({ commit, state }, { filter, type }) {
      if (type !== state.type) {
        commit(Mutation.SET_STATUS, RmaStates.LOADING);
      } else {
        commit(Mutation.SET_STATUS, RmaStates.UPDATING);
      }

      return new Promise((resolve, reject) => {
        getRmas(filter)
          .then(rmas => {
            commit(Mutation.SET_TYPE, type);
            commit(Mutation.SET_RMAS, rmas);
            commit(Mutation.SET_STATUS, RmaStates.READY);

            resolve(rmas);
          })
          .catch(err => {
            if (state.rmas.length === 0) {
              commit(Mutation.SET_STATUS, RmaStates.ERROR);
            } else {
              commit(Mutation.SET_STATUS, RmaStates.UPDATE_ERROR);
            }

            reject(err);
          });
      });
    },
  },

  mutations: {
    [Mutation.SET_RMAS](state, rmas) {
      state.rmas = rmas;
    },

    [Mutation.SET_STATUS](state, status) {
      state.status = status;
    },

    [Mutation.SET_TYPE](state, status) {
      state.type = status;
    },
  },
};
