//POST rma/create
//POST rma/delete/{id}
//POST rma/close/{id}
//POST rma/deleteline/{}
//POST rma/addline

import { Api } from '../';

import { RmaStatus } from '@types/Rma';

function parseRma(response) {
  return response.data.data ?? response.data;
}

export function upsertLineComment(comment, commentId, rma_line_id, rma_id) {
  const data = {
    rma_id,
    rma_line_id,
    comment,
  };

  if (commentId !== undefined) {
    data.id = commentId;
  }

  return new Promise((resolve, reject) => {
    Api.post(`rma/UpsertLineComment`, data)
      .then(response => {
        resolve(response.data.data ?? response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function upsertComment(comment, commentId, rma_id) {
  const data = {
    rma_id,
    comment,
  };

  if (commentId !== undefined) {
    data.id = commentId;
  }

  return new Promise((resolve, reject) => {
    Api.post(`rma/UpsertComment`, data)
      .then(response => {
        resolve(response.data.data ?? response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getRmas(filter = '') {
  return new Promise((resolve, reject) => {
    Api.get(filter !== '' ? '/RMA/GetAll?' + filter : '/RMA/GetAll')
      .then(response => {
        const rmas = response.data.data ?? response.data;

        resolve(rmas.filter(rma => rma.status !== RmaStatus.DELETED)); // Deleted rmas must not currently be visible
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getRma(rma_id) {
  return new Promise((resolve, reject) => {
    Api.get(`rma/Get/${rma_id}`)
      .then(response => {
        resolve(parseRma(response));
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function createRma(params) {
  return new Promise((resolve, reject) => {
    Api.post(`rma/Create`, {
      ...params,
    })
      .then(response => {
        resolve(response.data.data ?? response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function deleteRma(rma_id) {
  return new Promise((resolve, reject) => {
    Api.post(`rma/Delete/${rma_id}`)
      .then(response => {
        resolve(response.data.data ?? response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function closeRma(rma_id) {
  return new Promise((resolve, reject) => {
    Api.post(`rma/Close/${rma_id}`)
      .then(response => {
        resolve(response.data.data ?? response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
export function addLines(lines) {
  return new Promise((resolve, reject) => {
    Api.post(`rma/addlines`, lines)
      .then(response => {
        resolve(parseRma(response));
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function updateLine(line) {
  return new Promise((resolve, reject) => {
    Api.post(`rma/updateline`, line)
      .then(response => {
        resolve(parseRma(response));
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function removeLine(rma_id, rma_line_id) {
  return new Promise((resolve, reject) => {
    Api.post(`rma/deleteline`, {
      rma_id,
      rma_line_id,
    })
      .then(response => {
        resolve(parseRma(response));
      })
      .catch(error => {
        reject(error);
      });
  });
}
/**
 *
 * @param {String} query
 */
export function getInvoiceLinesByQuery(query) {
  return new Promise((resolve, reject) => {
    Api.post(`rma/invoices/querylines/${query.replace(/\./g, '%2E')}`)
      .then(response => {
        resolve(response.data.data ?? response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 *
 * @param {String} query
 */
export function getInvoiceLines(invoiceNumber) {
  return new Promise((resolve, reject) => {
    Api.get(`rma/invoices/${invoiceNumber}/lines`)
      .then(response => {
        resolve(response.data.data ?? response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
