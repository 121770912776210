<template>
  <div class="EmptyStateDialog">
    <div class="InnerWrapper">
      <h4 class="Header">{{ title }}</h4>
      <p class="Message">{{ message }}</p>
    </div>

    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'EmptyStateDialog',

  props: {
    title: {
      required: true,
      type: String,
      default: '',
    },
    message: {
      required: true,
      type: String,
      default: '',
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      required: false,
      type: String,
      default: 'fa-exclamation-triangle',
    },
  },
};
</script>

<style lang="scss" scoped>
.EmptyStateDialog {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
}

.InnerWrapper {
  width: 250px;
  max-width: 80%;
}

.Icon {
  font-size: 60px;
}

.Header {
  margin: 20px 0 15px 0;
}

.Message {
  color: $color-prim-grey-dark;
}
</style>
