import RmaModule from '@store/modules/rma';

import { mapActions, mapState, mapGetters } from 'vuex';
import { RmaStatus } from '@types/Rma';
export default {
  name: 'RmaContainer',
  data() {
    return {
      saveDrafts: false,
    };
  },
  computed: {
    ...mapState('rma', {
      rma: state => state.rma,
      lines: state => state.rma.lines,
      status: state => state.status,
      draftLines: state => state.draftLines,
    }),
    ...mapGetters('rma', [
      'getDraftLine',
      'getDraftLinesQuantity',
      'getDraftLineQuantity',
      'getLatestReturnMethodId',
      'getReturnMethodIds',
    ]),
    rmaStatus() {
      return {
        text: this.$t('rma.status.' + this.rma.status),
      };
    },
    returnMethodIds() {
      if (this.lines) {
        return [...new Set(this.lines.map(line => line.return_method_id || 'other'))]; // "" will be merged with "other".
      }
      return [];
    },
  },

  methods: {
    ...mapActions('rma', [
      'upsertDraftLine',
      'removeDraftLine',
      'submitDraftLines',
      'clearDraftLines',
      'upsertComment',
      'upsertLineComment',
      'updateLine',
      'addLine',
      'removeLine',
      'createRma',
      'changeRma',
      'submitRma',
      'deleteRma',
      'setReturnMethodId',
    ]),
    enableSaveDrafts() {
      this.saveDrafts = true;
    },
    disableSaveDrafts() {
      this.saveDrafts = false;
    },
  },

  created() {
    this.statuses = RmaStatus;
    if (!this.$store.state.rma) {
      this.$store.registerModule('rma', RmaModule);
    }
  },

  render() {
    return this.$scopedSlots.default({
      // State
      rma: this.rma ?? {},
      lines: this.lines ?? [],
      status: this.status,

      // Computed
      draftLine: this.getDraftLine,
      draftLineQuantity: this.getDraftLineQuantity,
      draftLinesQuantity: this.getDraftLinesQuantity,
      getLatestReturnMethodId: this.getLatestReturnMethodId,
      getReturnMethodIds: this.getReturnMethodIds,
      // Methods
      upsertDraftLine: this.upsertDraftLine,
      removeDraftLine: this.removeDraftLine,
      submitDraftLines: this.submitDraftLines,
      clearDraftLines: this.clearDraftLines,
      upsertComment: this.upsertComment,
      upsertLineComment: this.upsertLineComment,
      updateLine: this.updateLine,
      addLine: this.addLine,
      removeLine: this.removeLine,
      createRma: this.createRma,
      changeRma: this.changeRma,
      submitRma: this.submitRma,
      deleteRma: this.deleteRma,
      setReturnMethodId: this.setReturnMethodId,
      returnMethodIds: this.returnMethodIds,
    });
  },
  provide() {
    return {
      rmaContainer: this,
    };
  },
};
