<template>
  <RmaContainer ref="rmaContainer">
    <div>
      <SelfservicePageLayout>
        <template #controls>
          <Button @click="createNewRma"><i class="far fa-plus"></i> Opret ny</Button>
        </template>
        <template #default>
          <template v-if="$config.DOMAIN_CODE === 'VAU'">
            <p>
              Returnering er kun muligt for kurante og ubrugte varer i ubeskadiget original
              emballage og skal altid ske via: eshop.addanmark.dk -> Konto -> Returnering.
            </p>

            <p>
              Returnering indenfor 14 dage efter at levering sker til 100% købspris. Undtaget er
              skaffevarer og original dele.
            </p>
            <p>
              Sker returnering efter 14 til 30 dage efter levering er sket, er AD Danmark berettiget
              til efter eget valg, at afvise returneringen og/eller beregne sig et returgebyr på 10%
              af det returneredes værdi.
            </p>
            <p>
              Sker returnering mere end 30 dage efter levering er sket, er AD Danmark berettiget til
              efter eget valg, at afvise returneringen og/eller beregne sig et returgebyr på 25% af
              det returneredes værdi.
            </p>
            <p>
              Forsendelse af returvarer sker for Købers regning og risiko.
            </p>
            <p>
              Varer med depositum <b style="text-decoration:underline;">skal</b> returneres indenfor
              14 dage i original emballage for den vare, som er købt med depositum. Returnering af
              depositum varer efter 14 dage vil blive afvist.
            </p>
            <p>
              Skaffevarer og originaldele tages ikke retur.
            </p>
            <p>
              Varer som har været monteret eller er brugte krediteres ikke, og der opkræves i disse
              tilfælde et håndteringsgebyr på kr. 250,00 pr. vare.
            </p>
            <p>
              Returneringer der ikke foretages via eshop.addanmark.dk -> Konto -> Returnering bliver
              pålagt et returgebyr på kr. 50,00 pr. returnering.
            </p>
            <br />
          </template>
          <RmasTable />
        </template>
      </SelfservicePageLayout>
    </div>
  </RmaContainer>
</template>

<script>
import RmasTable from '../../components/RmasTable';

import SelfservicePageLayout from '@scenes/SelfserviceScene/layouts/Page';
import { Button } from '@components/Buttons';
import RmaContainer from '@scenes/SelfserviceScene/containers/RmaContainer';

export default {
  name: 'RmaDefaultOverview',

  components: {
    SelfservicePageLayout,
    Button,
    RmaContainer,
    RmasTable,
  },

  methods: {
    createNewRma() {
      this.$refs.rmaContainer.createRma().then(rmaId => {
        this.$router.push({ name: '/selfservice/returns/:id', params: { id: rmaId } });
      });
    },
  },
};
</script>
