import Vue from 'vue';

import {
  getRma,
  createRma,
  closeRma,
  deleteRma,
  addLines,
  removeLine,
  updateLine,
  upsertComment,
  upsertLineComment,
} from '@services/thansen/rma';

import { Mutation, State } from './types';
import { RmaStates } from '@types/Rma';

const lineExists = (line, existingLine) => {
  if (
    line.invoice_line_id &&
    line.invoice_line_id === existingLine.invoice_line_id &&
    line.invoice_number &&
    line.invoice_number === existingLine.invoice_number
  ) {
    return true;
  } else if (line.item_reference && line.item_reference === existingLine.item_reference) {
    return true;
  } else if (
    line.item_reference === undefined &&
    line.invoice_line_id === undefined &&
    line.invoice_number &&
    line.invoice_number === existingLine.invoice_number
  ) {
    return true;
  }

  return false;
};

export default {
  namespaced: true,
  state: {
    rma: {},
    status: '',
    draftLines: [],
    defaultReturnMethodId: '',
  },

  getters: {
    /** Returns return_method_id for the last added draft line (that has an return_method_id) */
    getLatestReturnMethodId: state => {
      if (state.draftLines.length > 0) {
        const draftLine = state.draftLines
          .slice()
          .reverse()
          .find(line => {
            return line.return_method_id && line.return_method_id !== undefined;
          });

        if (draftLine !== undefined && draftLine.return_method_id !== undefined) {
          return draftLine.return_method_id;
        } else if (state.defaultReturnMethodId !== '') {
          return state.defaultReturnMethodId;
        }
      }
      return state.defaultReturnMethodId;
    },
    /** Returns return_method_id's for all added lines */
    getReturnMethodIds: state => {
      const ids = [];
      if (state.rma && state.rma.lines && state.rma.lines.length > 0) {
        state.rma.lines.forEach(line => {
          if (line.return_method_id && ids.indexOf(line.return_method_id) === -1) {
            ids.push(line.return_method_id);
          }
        });
      }
      return ids;
    },
    /** Returns a quantity for a single line.
     * The lookupline object must contain relevant properties like "invoice_number and invoice_line_id or item_reference".
     */
    getDraftLineQuantity: state => lookupLine => {
      const lines = state.draftLines.filter(draftLine => {
        return lineExists(lookupLine, draftLine);
      });
      return lines.reduce((a, b) => a + (b['quantity'] || 0), 0);
    },
    /** Gets a single draft line
     * The lookupline object must contain relevant properties like "invoice_number and invoice_line_id or item_reference".
     */
    getDraftLine: state => lookupLine => {
      return state.draftLines.find(draftLine => {
        return lineExists(lookupLine, draftLine);
      });
    },
    /** Gets the sum of all draft lines quantity */
    getDraftLinesQuantity: state => {
      return state.draftLines.reduce((acc, el) => acc + el.quantity, 0);
    },
  },

  actions: {
    /**
     * Adds or updates a specific line.
     * Return method id is set to default to last line added with an return_method_id.
     * @param {*} param0 vuex
     * @param {*} line The line object. Check Mutation.UPSERT_DRAFT_LINE for mandatory attributes.
     */
    upsertDraftLine({ dispatch, commit, getters, state }, line) {
      if (line.quantity === 0) {
        dispatch('removeDraftLine', line);
      } else {
        if (
          (line.return_method_id === '' || line.return_method_id == undefined) &&
          getters.getLatestReturnMethodId !== undefined
        ) {
          commit(Mutation.UPSERT_DRAFT_LINE, {
            ...line,
            ...{ return_method_id: getters.getLatestReturnMethodId },
          });
        } else {
          commit(Mutation.UPSERT_DRAFT_LINE, line);
        }
      }
    },
    /**
     * Removes a single line from the draft lines.
     * @param {*} param0 vuex
     * @param {*} line The line object. Check Mutation.UPSERT_DRAFT_LINE for mandatory attributes.
     */
    removeDraftLine({ commit, state }, line) {
      commit(Mutation.REMOVE_DRAFT_LINE, line);
    },
    /**
     * Prepares, submits and clears all draft lines.
     */
    submitDraftLines({ commit, state }, rmaId, rmaType) {
      return new Promise((resolve, reject) => {
        commit(Mutation.SET_STATUS, RmaStates.UPDATING);
        const lines = state.draftLines.map(line => {
          const linePreset = {
            rma_id: rmaId || state.rma.id,
            rma_type: rmaType || state.rma.type || 'ReturnOfGoods', // Default to ReturnOfGoods.
            return_method: 'SpecialDeliveryContainer', // Will be dynamic when b2c is added
          };
          return { ...linePreset, ...line };
        });
        addLines(lines)
          .then(rma => {
            commit(Mutation.SET_RMA, rma);
            commit(Mutation.CLEAR_DRAFT_LINES);
            commit(Mutation.SET_STATUS, RmaStates.READY);
            resolve(rma);
          })
          .catch(err => {
            //commit(Mutation.SET_STATUS, RmaStates.ERROR);
            reject(err);
          });
      });
    },
    clearDraftLines({ commit }) {
      commit(Mutation.CLEAR_DRAFT_LINES);
    },
    upsertComment({ commit, state }, { comment, commentId }) {
      commit(Mutation.SET_STATUS, RmaStates.UPDATING);

      return new Promise((resolve, reject) => {
        upsertComment(comment, commentId, state.rma.id)
          .then(comment => {
            commit(Mutation.UPSERT_COMMENT, comment);
            commit(Mutation.SET_STATUS, RmaStates.READY);
            resolve(comment.id);
          })
          .catch(err => {
            //commit(Mutation.SET_STATUS, RmaStates.ERROR);
            reject(err);
            commit(Mutation.SET_STATUS, RmaStates.UPDATE_ERROR);
          });
      });
    },

    upsertLineComment({ commit, state }, { comment, rma_line_id, commentId }) {
      commit(Mutation.SET_STATUS, RmaStates.UPDATING);

      // Do some POST here before commint the mutation
      // This is currently not a working method
      return new Promise((resolve, reject) => {
        upsertLineComment(comment, commentId, rma_line_id, state.rma.id)
          .then(comment => {
            commit(Mutation.UPSERT_LINE_COMMENT, { comment, rma_line_id });
            commit(Mutation.SET_STATUS, RmaStates.READY);
            resolve(comment.id);
          })
          .catch(err => {
            //commit(Mutation.SET_STATUS, RmaStates.ERROR);
            reject(err);
            commit(Mutation.SET_STATUS, RmaStates.UPDATE_ERROR);
          });
      });
    },
    /**
     * Fetches and replaces the current RMA.
     * @param {*} vuex
     * @param {String} id The RMA ID
     */
    changeRma({ commit, state }, id) {
      commit(Mutation.SET_STATUS, RmaStates.LOADING);
      return new Promise((resolve, reject) => {
        commit(Mutation.SET_RETURN_METHOD_ID, '');
        commit(Mutation.SET_RMA, { id: id });

        getRma(id)
          .then(rma => {
            commit(Mutation.SET_RMA, rma);
            commit(
              Mutation.SET_STATUS,
              rma.status !== 'Deleted' ? RmaStates.READY : RmaStates.DELETED,
            );
            resolve(rma.rma_id);
          })
          .catch(err => {
            //commit(Mutation.SET_STATUS, RmaStates.ERROR);
            reject(err);
          });
      });
    },
    /**
     *
     * @param {*} param0 vuex
     * @param {Object} options Options for the creation, like "{type:'Complaint'}".
     */
    createRma({ commit, state }, options) {
      //commit(Mutation.SET_STATUS, RmaStates.CREATING);
      return new Promise((resolve, reject) => {
        createRma(options)
          .then(rma => {
            //commit(Mutation.SET_RMA, { id: rma.rma_id });
            //commit(Mutation.SET_STATUS, RmaStates.READY);
            resolve(rma.rma_id);
          })
          .catch(err => {
            //commit(Mutation.SET_STATUS, RmaStates.ERROR);
            reject(err);
          });
      });
    },
    /**
     * Submits/Closes the current RMA.
     * @param {*} param0 vuex
     */
    submitRma({ commit, state }) {
      commit(Mutation.SET_STATUS, RmaStates.CLOSING);
      return new Promise((resolve, reject) => {
        closeRma(state.rma.id)
          .then(rma => {
            commit(Mutation.SET_RMA, rma);
            commit(Mutation.SET_STATUS, RmaStates.READY);
            resolve(rma);
            //commit(Mutation.SET_RMA, rma);
          })
          .catch(err => {
            //commit(Mutation.SET_STATUS, RmaStates.ERROR);
            reject(err);
          });
      });
    },
    deleteRma({ commit, state }, rma_id) {
      commit(Mutation.SET_STATUS, RmaStates.UPDATING);
      return new Promise((resolve, reject) => {
        deleteRma(rma_id || state.rma.id)
          .then(status => {
            commit(Mutation.SET_RMA, {});
            commit(Mutation.SET_STATUS, '');
            resolve(status);
            //commit(Mutation.SET_RMA, rma);
          })
          .catch(err => {
            //commit(Mutation.SET_STATUS, RmaStates.ERROR);
            reject(err);
          });
      });
    },
    /**
     *
     * @param {*} param0 vuex
     * @param {Object} line Updates the line with the supplied properties. "id" is mandatory
     */
    updateLine({ commit, state }, line) {
      commit(Mutation.SET_STATUS, RmaStates.UPDATING);
      // TODO: Check for line.id?
      const linePreset = {
        rma_id: state.rma.id,
        rma_line_id: line.id,
      };

      updateLine({ ...linePreset, ...line })
        .then(rma => {
          commit(Mutation.UPDATE_RMA, { lines: rma.lines });
          commit(Mutation.SET_STATUS, RmaStates.READY);
        })
        .catch(err => {
          commit(Mutation.SET_STATUS, RmaStates.UPDATE_ERROR);
        });
    },
    /**
     * Removes a single line from the rma lines array.
     * @param {*} param0 vuex
     * @param {String} lineId Id of the line to remove
     */
    removeLine({ commit, state }, lineId) {
      commit(Mutation.SET_STATUS, RmaStates.UPDATING);
      commit(Mutation.REMOVE_LINE, lineId);
      removeLine(state.rma.id, lineId)
        .then(response => {
          commit(Mutation.SET_STATUS, RmaStates.READY);
        })
        .catch(err => {
          commit(Mutation.SET_STATUS, RmaStates.UPDATE_ERROR);
        });
    },

    setReturnMethodId({ commit, state }, returnMethodId) {
      commit(Mutation.SET_RETURN_METHOD_ID, returnMethodId);
    },
  },
  mutations: {
    [Mutation.UPSERT_LINE_COMMENT](state, { comment, rma_line_id }) {
      const stateLineIndex = state.rma.lines.findIndex(rmaLine => rmaLine.id === rma_line_id);

      // If the line actually exists
      if (stateLineIndex > -1) {
        const commentIndex = state.rma.lines[stateLineIndex].comments.findIndex(
          lineComment => lineComment.id === comment.id,
        );

        if (commentIndex > -1) {
          // If it exists then update it
          state.rma.lines[stateLineIndex].comments[commentIndex] = comment;
        } else {
          // Otherwise add it to the array
          state.rma.lines[stateLineIndex].comments.push(comment);
        }
      }
    },
    [Mutation.UPSERT_COMMENT](state, comment) {
      const commentIndex = state.rma.comments.findIndex(
        headComment => headComment.id === comment.id,
      );

      if (commentIndex !== -1) {
        // If it exists then update it
        Vue.set(state.rma.comments, commentIndex, comment);
      } else {
        // Otherwise add it to the array
        state.rma.comments.push(comment);
      }
    },
    [Mutation.SET_RETURN_METHOD_ID](state, returnMethodId) {
      state.defaultReturnMethodId = returnMethodId;
    },
    [Mutation.SET_STATUS](state, status) {
      state.status = status;
    },

    [Mutation.SET_RMA](state, rma) {
      state.rma = rma;
    },

    [Mutation.UPDATE_RMA](state, rma) {
      state.rma = Object.assign({}, state.rma, rma);
    },

    [Mutation.REMOVE_LINE](state, lineId) {
      const lineIndex = state.rma.lines.findIndex(line => line.id === lineId);
      if (lineIndex !== -1) {
        state.rma.lines.splice(lineIndex, 1);
      }
    },

    [Mutation.UPSERT_DRAFT_LINE](state, upsertLine) {
      const existingLineIndex = state.draftLines.findIndex(draftLine => {
        return lineExists(upsertLine, draftLine);
      });
      if (existingLineIndex !== -1) {
        Vue.set(state.draftLines, existingLineIndex, {
          ...state.draftLines[existingLineIndex],
          ...upsertLine,
        });
      } else {
        state.draftLines.push(upsertLine);
      }
    },
    [Mutation.REMOVE_DRAFT_LINE](state, removeLine) {
      const lineIndex = state.draftLines.findIndex(draftLine => {
        return lineExists(removeLine, draftLine);
      });
      if (lineIndex !== -1) {
        state.draftLines.splice(lineIndex, 1);
      }
    },
    [Mutation.CLEAR_DRAFT_LINES](state) {
      state.draftLines = [];
    },
  },
};
