<template>
  <RmaOverviewContainer ref="rmaOverviewContainer" v-slot="{ rmas, status }" :type="type">
    <div>
      <ProgressOverlay
        :progress="
          status === rmaStates.LOADING ||
            status === rmaStates.CLOSING ||
            status === rmaStates.UPDATING
        "
        :hide-content-on-progress="status === rmaStates.UPDATING ? false : true"
      >
        <Table
          v-if="rmas.length"
          itemKey="id"
          :items="rmas"
          clickable
          @click="
            item => $router.push({ name: '/selfservice/returns/:id', params: { id: item.id } })
          "
        >
          <TableColumn v-slot="{ item }" header="Returnerings id">
            {{ item.id }}
          </TableColumn>
          <TableColumn v-slot="{ item }" header="Oprettet">
            <Created :data="item" :index="0" />
          </TableColumn>
          <TableColumn v-slot="{ item }" header="Antal varer">
            <LinesQuantity :data="item" :index="0" />
          </TableColumn>
          <TableColumn v-slot="{ item }" header="Status">
            <Status :data="item" :index="0" />
          </TableColumn>
        </Table>
        <EmptyStateDialog
          v-else
          title="Ingen returneringer fundet"
          message="Vi kunne ikke finde nogen returneringer for denne konto."
        />
      </ProgressOverlay>
    </div>
  </RmaOverviewContainer>
</template>

<script>
import { RmaStates } from '@types/Rma';

import { Table, TableColumn } from '@components/Table';
import EmptyStateDialog from '@components/EmptyStateDialog';
import ProgressOverlay from '@components/ProgressOverlay';

import Created from './components/Created';
import LinesQuantity from './components/LinesQuantity';
import Status from './components/Status';

import RmaOverviewContainer from '../../containers/RmaOverviewContainer';

export default {
  name: 'RmasTable',
  components: {
    RmaOverviewContainer,
    Table,
    TableColumn,
    EmptyStateDialog,
    ProgressOverlay,
    Created,
    LinesQuantity,
    Status,
  },

  props: {
    // Type is beging used for the store as a string prop.
    // When getting new rmas we will check for the prop. If the type
    // is the same, then we'll set the status to UPDATING.
    // Otherwise the status will be set to LOADING
    //
    // This will  have an impact on how content is shown or hidden
    // while our loading spinner is active
    //
    type: {
      required: false,
      type: String,
      default: 'returns',
    },
  },

  created() {
    this.rmaStates = RmaStates;
  },

  mounted() {
    this.$refs.rmaOverviewContainer.getRmasWithType(this.type);
  },
};
</script>
