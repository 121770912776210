<template>
  <span
    :class="[
      'StatusLabel',
      'StatusLabel--' + type,
      solid && 'StatusLabel--solid',
      light && 'StatusLabel--light',
    ]"
  >
    <div v-if="hasIconSlot" class="StatusLabel__icon">
      <slot name="icon"> </slot>
    </div>
    <div class="StatusLabel__content">
      <slot name="default" />
    </div>
  </span>
</template>

<script>
import { types } from './types';
export default {
  name: 'StatusLabel',
  props: {
    type: {
      type: String,
      default: '',
    },
    solid: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasIconSlot() {
      return !!this.$slots['icon'] || !!this.$scopedSlots['icon'];
    },
  },
  created() {
    this.types = types;
  },
};
</script>

<style lang="scss" scoped>
.StatusLabel {
  font-weight: bold;
  //text-transform: uppercase;
  display: inline-flex;
  font-size: 14px;
  gap: 0.3em;
}
.StatusLabel--light {
  text-transform: none;
}
.StatusLabel i {
  margin-right: 0.2em;
}

.StatusLabel--error {
  color: $color-extra-error;
}
.StatusLabel--warning {
  color: $color-extra-warning;
}
.StatusLabel--success {
  color: $color-extra-success;
}
.StatusLabel--info {
  color: $color-extra-info;
}
.StatusLabel--trace {
  color: $color-extra-trace;
}
.StatusLabel--solid {
  padding: 0.3em 0.8em 0.2em;
  font-weight: normal;
}
.StatusLabel--solid.StatusLabel--error {
  color: white;
  background: $color-extra-error !important;
}
.StatusLabel--solid.StatusLabel--warning {
  color: white;
  background: $color-extra-warning !important;
}
.StatusLabel--solid.StatusLabel--success {
  color: white;
  background: $color-extra-success !important;
}
.StatusLabel--solid.StatusLabel--info {
  color: white;
  background: $color-extra-info !important;
}
.StatusLabel--solid.StatusLabel--trace {
  color: white;
  background: $color-extra-trace !important;
}

@mixin printStyles() {
  .StatusLabel--solid.StatusLabel--error {
    border: 1px solid $color-extra-error;
  }
  .StatusLabel--solid.StatusLabel--warning {
    border: 1px solid $color-extra-warning;
  }
  .StatusLabel--solid.StatusLabel--success {
    border: 1px solid $color-extra-success;
  }
  .StatusLabel--solid.StatusLabel--info {
    border: 1px solid $color-extra-info;
  }
}

@media print {
  @include printStyles();
}

.ForcedPrint {
  @include printStyles();
}
</style>
