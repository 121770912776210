export const ReturnMethod = {
  UNKNOWN: 'unknown',
  SPECIAL_DELIVERY_CONTAINER: 'special_delivery_container',
  DEPARTMENT_DROPOFF: 'department_dropOff',
  DELIVERY: 'delivery',
};

export const RmaType = {
  DEFAULT: 'default',
  RETURN: 'retur',
  COMPLAINT: 'reklamation',
};

export const RmaStates = {
  CREATING: 'CREATING',
  CREATE_ERROR: 'CREATE_ERROR',
  CLOSING: 'CLOSING',
  READY: 'READY',
  DELETED: 'DELETED',
  LOADING: 'LOADING',
  UPDATING: 'UPDATING',
  UPDATE_ERROR: 'UPDATE_ERROR',
  DELETING: 'DELETING',
  DELETE_ERROR: 'DELETE_ERROR',
  ERROR: 'ERROR',
};

export const RmaStatus = {
  OPEN: 'open',
  CLOSED: 'closed',
  DELETED: 'deleted',
};

export const RmaFilterTypes = {
  COMPLAINT: 'complaint',
  RETURN_OF_GOODS: 'returnOfGoods',
};
