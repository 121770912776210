import RmaOverviewModule from '@store/modules/rmaOverview';

import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'RmaOverviewContainer',

  props: {
    // Type is beging used for the store as a string prop.
    // When getting new rmas we will check for the prop. If the type
    // is the same, then we'll set the status to UPDATING.
    // Otherwise the status will be set to LOADING
    //
    // This will  have an impact on how content is shown or hidden
    // while our loading spinner is active
    //
    type: {
      required: false,
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState('rmaOverview', {
      rmas: state => state.rmas,
      status: state => state.status,
    }),
    ...mapGetters('rmaOverview', []),
  },

  methods: {
    ...mapActions('rmaOverview', ['getRmas']),

    getRmasWithType(filters) {
      return this.getRmas({ filter: filters, type: this.type });
    },
  },

  created() {
    if (!this.$store.state.rmaOverview) {
      this.$store.registerModule('rmaOverview', RmaOverviewModule);
    }
  },

  render() {
    return this.$scopedSlots.default({
      // State
      rmas: this.rmas,
      status: this.status,

      // Methods
      getRmas: this.getRmas,
    });
  },

  provide() {
    return {
      rmaOverviewContainer: this,

      getRmasWithType: this.getRmasWithType,
    };
  },
};
