<template>
  <div>{{ linesQuantity }}</div>
</template>

<script>
export default {
  name: 'LinesQuantity',

  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  computed: {
    linesQuantity() {
      const sumTotal = arr => {
        return arr === undefined ? 0 : arr.reduce((sum, { quantity }) => sum + quantity, 0);
      };

      return sumTotal(this.data.lines);
    },
  },
};
</script>
