<template>
  <div>
    <Time :datetime="data.created" format="LL" /><br />
    <span class="TimeFrom">{{ $t('updated') }} <TimeFrom :datetime-from="data.updated"/></span>
  </div>
</template>

<script>
import Time from '@components/Time';
import TimeFrom from '@components/TimeFrom';

export default {
  name: 'Created',

  components: {
    Time,
    TimeFrom,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.TimeFrom {
  font-style: italic;
  color: $color-off-grey;
}
</style>
