<template>
  <time>{{ formattedDatetimeFrom }}</time>
</template>

<script>
import navigatorLanguages from 'navigator-languages';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import './locales/nb';
import './locales/da';

dayjs.extend(relativeTime);

export default {
  name: 'TimeFrom',

  props: {
    datetimeFrom: {
      type: String,
      required: true,
    },
  },

  computed: {
    formattedDatetimeFrom() {
      const datetime = new Date().toString();

      const { showDate, showTime, datetimeFrom } = this;

      if (datetimeFrom && datetime) {
        const preferredLanguage = navigatorLanguages()[0];
        let language = 'da';

        switch (preferredLanguage) {
          case 'no':
          case 'nb-NO':
            language = 'nb';
            break;
          case 'nn-NO':
            language = 'nn';
            break;
        }

        // Set locale
        dayjs.locale(language);

        const from = dayjs(datetimeFrom);
        const to = dayjs(datetime);

        return from.from(to); // "a day ago"
      }

      // Return an empty string if we're not served a date
      return '';
    },
  },
};
</script>
